
import { Options, prop, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import CheckBox from "@/app/ui/components/check-box/index.vue";

class Props {
  options = prop<Array<any>>({
    default: [],
    type: Array
  });
  errorDataShow = prop<Array<any>>({
    default: [],
    type: Array
  });
  optionName = prop<string>({
    default: "Item",
    type: String
  });
  modelValue = prop<Array<any>>({
    default: [],
    type: Array
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  disableBackground = prop<boolean>({
    default: false,
    type: Boolean
  });
  allEnabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
  isError = prop<boolean>({
    default: false,
    type: Boolean
  });
  placeholder = prop<string>({
    default: "",
    type: String
  });
  keyName = prop<string>({
    default: "name",
    type: String
  });
  keyValue = prop<string>({
    default: "value",
    type: String
  });
  modalTitle = prop<string>({
    default: "",
    type: String
  });
  maxItemDisplayed = prop<number>({
    default: 999,
    type: Number
  });
  closeOnSelecting = prop<boolean>({
    default: false,
    type: Boolean
  });
  error = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorCaption = prop<string>({
    default: "",
    type: String
  });
  serverSide = prop<boolean>({
    default: true,
    type: Boolean
  });
  showReset = prop<boolean>({
    default: false,
    type: Boolean
  });
  showSelected = prop<boolean>({
    default: true,
    type: Boolean
  });
  showSelectedInInput = prop<boolean>({
    default: false,
    type: Boolean
  });
  hideInput = prop<boolean>({
    default: false,
    type: Boolean
  });
  ableToDeleteInModal = prop<boolean>({
    default: false,
    type: Boolean
  });
  displayValue = prop<boolean>({
    default: false,
    type: Boolean
  });
  customDisplayAll = prop<string>({
    default: "Pilih Semua",
    type: String
  });
  noteForBosspack = prop<boolean>({
    default: false,
    type: Boolean
  });
  disabledBosspack = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: [
    "focus",
    "blur",
    "change",
    "filter",
    "reset",
    "update:modelValue",
    "update:isError",
    "remove"
  ],
  components: {
    Skeleton,
    CheckBox
  },
  directives: {
    "click-outside": {
      beforeMount: function(el: any, binding: any) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el: any) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  },
  watch: {
    modelValue() {
      this.$emit("update:isError", !this.modelValue.length);
    }
  }
})
export default class Select extends Vue.with(Props) {
  // logic open close menu
  isChevron = false;
  isOpenMenu = false;
  onOpenSelect(value: boolean) {
    if (value) {
      this.$emit("focus", "");
    }
    if (!value) {
      this.filterSearch = "";
      this.$emit("blur", "");
    }
    this.isOpenMenu = value;
  }

  modelDialog = false;

  //logic filter search
  get selectedItemsName() {
    return this.modelValue.length > 0
      ? this.modelValue.map((item: any) => item[this.keyName]).join(", ")
      : "";
  }
  filterSearch = "";
  get dataOptions() {
    if (this.serverSide) {
      return this.options;
    }
    const tempOptions = this.options.filter((option: any) => {
      return option[this.keyName]
        ? option[this.keyName]
            .toLowerCase()
            .includes(this.filterSearch.toLowerCase())
        : option.toLowerCase().includes(this.filterSearch.toLowerCase());
    });
    if (this.allEnabled && !this.filterSearch) {
      const allObject: any = {};
      allObject[this.keyName] = "All";
      allObject[this.keyValue] = "all";
      tempOptions.unshift(allObject);
    }
    return tempOptions;
  }
  onSearch() {
    this.$emit("filter", this.filterSearch);
  }

  // logic for selected item
  isSelected(item: any) {
    if (`${item[this.keyValue]}`.toLowerCase() === "all" && this.allEnabled) {
      return this.isAll();
    }
    return this.modelValue.find((data: any) => {
      return data[this.keyValue]
        ? data[this.keyValue] === item[this.keyValue]
        : data === item;
    });
  }
  isAll() {
    return (
      (this.modelValue.length === this.options.length && this.allEnabled) ||
      !!this.modelValue?.find(
        (item: any) => `${item[this.keyValue]}`.toLowerCase() === "all"
      )
    );
  }
  onChooseSelect(item: any, index: number) {
    if (
      ((this.isAll() && `${item[this.keyValue]}`.toLowerCase() !== "all") ||
        (!this.isAll() &&
          this.modelValue.length > 0 &&
          `${item[this.keyValue]}`.toLowerCase() === "all")) &&
      !this.allEnabled
    ) {
      this.modelValue.splice(0, this.modelValue.length);
      this.modelValue.push(item);
      this.$emit("change", [item]);
      this.$emit("update:modelValue", [item]);
      return;
    }
    if (`${item[this.keyValue]}` === "all" && this.allEnabled) {
      this.onChooseAll();
      return;
    }
    if (this.isSelected(item)) {
      const indexItemSelected = this.modelValue.findIndex((data: any) =>
        data[this.keyValue]
          ? data[this.keyValue] === item[this.keyValue]
          : data === item
      );
      this.$emit("remove", item, index);
      this.modelValue.splice(indexItemSelected, 1);
    } else {
      this.modelValue.push(item);
    }
    this.$emit("change", this.modelValue);
    this.$emit("update:isError", !this.modelValue.length);
  }
  onChooseAll() {
    if (this.isAll()) {
      this.modelValue.splice(0, this.modelValue.length);
    } else {
      this.modelValue.splice(0, this.modelValue.length);
      for (const option of this.options) {
        this.modelValue.push(option);
      }
    }
    this.$emit("change", this.modelValue);
  }

  get filterShowValue(): any[] {
    const data = this.modelValue.slice(0, this.maxItemDisplayed);
    if (this.errorDataShow.length) {
      return data.map((item: any) => {
        const find = this.errorDataShow.find(
          (el: any) => el[this.keyValue] === item[this.keyValue]
        );
        if (find) {
          return { ...item, isError: true };
        }
        return item;
      });
    }
    return data;
  }

  onReset() {
    this.$emit("change", []);
    this.$emit("update:modelValue", []);
    this.$emit("reset", "");
  }
}
